<template>
  <b-container :class="`mt-5 pt-5 ${containerClassName || ''}`">
    <b-row :class="`justify-content-center ${className || 'bg-white'}`">
      <b-col cols="5">
        <div class="d-flex">
          <div
            id="tour-pickup"
            class="card--sender mr-1"
            :class="{ 'active': delivery_pickup === 'pickup' }"
            @click="delivery_pickup = 'pickup'"
          >
            <div class="circle">
              <feather-icon
                class="shopping-cart"
                icon="ShoppingCartIcon"
              />
            </div>
            <h6>Ambil Sendiri</h6>
          </div>
          <div
            id="tour-delivery"
            class="card--sender"
            :class="{ 'active': delivery_pickup === 'delivery' }"
            @click="delivery_pickup = 'delivery'"
          >
            <div class="circle">
              <feather-icon
                class="truck"
                icon="TruckIcon"
              />
            </div>
            <h6>Dikirim</h6>
          </div>
        </div>
        <hr style="border: 1px solid #E4E5EC;">

        <b-form @submit.prevent="submit">
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <b-form-group class="custom__form--input mb-0">
                <label for="date-creation">Tanggal Transaksi <span class="text-danger">*</span></label>
                <b-form-input
                  id="date-creation"
                  v-model="formPayload.date"
                  class="custom__input pt-1"
                  type="date"
                  :max="todayDate"
                  placeholder="DD/MM/YYYY"
                  required
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              class="mb-2"
              v-if="delivery_pickup === 'delivery'"
            >
              <b-form-group class="custom__form--input mb-0">
                <label for="date-creation">Tanggal Pengiriman <span class="text-danger">*</span></label>
                <b-form-input
                  id="date-creation"
                  v-model="formPayload.delivery_date"
                  class="custom__input pt-1"
                  type="date"
                  placeholder="DD/MM/YYYY"
                  required
                />
              </b-form-group>
            </b-col>

            <!-- Sales -->
            <b-col
              cols="12"
              class="mb-2"
            >
              <b-form-group class="custom__form--input mb-0">
                <label for="v-sales">Sales</label>
                <v-select
                  v-model="formPayload.seller_uuid"
                  label="title"
                  :reduce="option => option.label"
                  :options="options"
                  placeholder="Pilih Sales"
                  class="text-dark fw-bold-700"
                  @search="onSearch"
                />
              </b-form-group>
            </b-col>
            <!-- Faktur, Non Faktur -->
            <b-col
              cols="12"
              class="mb-2"
            >
              <label class="text-dark fw-bold-600" style="font-size: 14px !important;">Pilih Faktur atau Non-faktur <span class="text-danger">*</span></label><br>
              <small class="size12 text-dark text-darken-4 fw-bold-600">Pilih faktur jika transaksi ini memerlukan faktur pajak</small>
              <b-form-group
                class="custom__form--input mb-0 mt-1"
              >
                <b-form-radio
                  v-model="invoice_status"
                  name="some-radios"
                  value="1"
                  class="mb-1"
                >
                  Faktur Pajak
                </b-form-radio>
                <b-form-radio
                  v-model="invoice_status"
                  name="some-radios"
                  value="0"
                >
                  Non-faktur Pajak
                </b-form-radio>
                <!-- <b-form-select
                  v-model="selectedFaktur"
                  :options="options"
                /> -->
              </b-form-group>
            </b-col>
            <!-- nomor po -->
            <b-col
              v-if="customerType !== 'Non Ritel'"
              cols="12"
              class="mb-2"
            >
              <b-form-group
                class="custom__form--input mb-0"
                label="Nomor PO / SO"
                label-for="v-nomor-po"
              >
                <b-form-input
                  id="v-nomor-po"
                  v-model="formPayload.po_so_number"
                  class="custom__input"
                  type="text"
                  placeholder="x-xxxx-xxxx"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="delivery_pickup === 'delivery'">
            <!-- nama penerima -->
            <b-col
              cols="12"
              class="mb-2"
            >
              <label label-for="penerima" class="text-dark fw-bold-600" style="font-size: 14px !important;">Nama Penerima<span class="text-danger">*</span></label>
              <b-form-group
                class="custom__form--input mb-0"
              >
                <b-form-input
                  id="penerima"
                  v-model="formPayloadDelivery.receiver_name"
                  class="custom__input"
                  placeholder="Nama Anda"
                />
              </b-form-group>
            </b-col>

            <!-- Nomor Handphone Penerima -->
            <b-col
              cols="12"
              class="mb-2"
            >
              <label label-for="hp" class="text-dark fw-bold-600" style="font-size: 14px !important;">Nomor Handphone Penerima<span class="text-danger">*</span></label>
              <b-form-group
                class="custom__form--input mb-0"
              >
                <b-form-input
                  id="hp"
                  v-model="formPayloadDelivery.receiver_phone_number"
                  type="number"
                  class="custom__input"
                  placeholder="6289112423432"
                  @input="handlePhoneNumberInput"
                />
              </b-form-group>
            </b-col>

            <!-- Alamat Penerima -->
            <b-col
              cols="12"
              class="mb-2 custom__form--input"
            >
              <!-- <label>Alamat Penerima (Opsional)</label>
              <QuilEditor :payload="formPayloadDelivery.receiver_address" @contentText="getContentText" /> -->
              <label label-for="address" class="text-dark fw-bold-600" style="font-size: 14px !important;">Alamat Penerima<span class="text-danger">*</span></label>
              <b-form-group
                class="custom__form--input mb-0"
              >
                <b-form-textarea
                  id="address"
                  v-model="formPayloadDelivery.receiver_address"
                  rows="3"
                  placeholder="Alamat"
                  class="custom__textarea"
                  maxlength="100"
                />
              </b-form-group>
              <small class="size12 text-dark text-darken-4 font-italic">
                *Maksimal 100 Karakter
              </small>
            </b-col>

            <b-col cols="12">
              <div class="d-flex align-items-center" v-for="(item, index) in additional_costs" :key="index">
                <b-form-group class="custom__form--input mr-1">
                  <label for="value-diskon">Nama Biaya</label>
                  <input
                    :id="`nama-biaya-${index}`"
                    type="text"
                    v-model="additional_costs[index].additional_fee_note"
                    class="custom__input pl-2 w-100"
                    placeholder="Masukan Nama Biaya"
                  >
                </b-form-group>
                <b-form-group class="custom__form--input">
                  <label for="value-diskon">Nominal Biaya</label>
                  <input
                    :id="`nominal-biaya-${index}`"
                    type="text"
                    :value="additional_costs[index].additional_fee_amount"
                    class="custom__input pl-2 w-100"
                    placeholder="Masukan Nominal Biaya"
                    @input="getRupiah(index)"
                    @change="getRupiah(index)"
                  >
                </b-form-group>
                <b-button id="button--hapus__biaya" class="p-1 ml-25" variant="outline-danger" @click="removeItem(index)">
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </div>
            </b-col>
            <b-button id="button--tambah__biaya" class="w-100" variant="outline-dark" @click="repeateAgain" v-if="additional_costs && additional_costs.length < 3">
              + Tambah Biaya Tambahan
            </b-button>
          </b-row>
          <b-row v-if="!hideButtonSubmit">
            <!-- Button -->
            <b-col
              cols="12"
              class="mb-2"
            >
              <b-button
                type="submit"
                :disabled="isLoading"
                class="btn-block size16 py-2 border-8"
              >
                <b-spinner
                  v-if="isLoading"
                  class="mr-1"
                  variant="primary"
                />
                Proses
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <app-tour :nameTour="'cashierTour7'" :steps="steps" />
  </b-container>
</template>

<script>
import _ from 'lodash'
import vSelect from 'vue-select'
import {
  BContainer, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormRadio, BSpinner, BFormTextarea
} from 'bootstrap-vue'
import moment from 'moment'
import AppTour from '@core/components/app-tour/AppTour.vue'
import { checkPermission, successNotification, errorNotification, preformatFloat } from '@/auth/utils'
import QuilEditor from '@/components/QuilEditor.vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormRadio,
    BButton,
    BSpinner,
    BFormTextarea,
    AppTour,
    QuilEditor,
    vSelect,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    submit: {
      type: Function,
    },
    className: {
      type: String,
    },
    containerClassName: {
      type: String,
    },
    hideButtonSubmit: {
      type: Boolean,
    }
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
      preformatFloat,
    }
  },
  data() {
    const newDate = new Date()
    const endDate = moment(newDate).format('YYYY-MM-DD')
    const customerData = JSON.parse(localStorage.getItem('customer_data'))

    return {
      invoice_status: '',
      todayDate: endDate,
      selectedFaktur: '',
      // options: [
      //   {
      //     value: '',
      //     text: 'Pilih Faktur atau Non Faktur',
      //   },
      //   {
      //     value: '1',
      //     text: 'Faktur',
      //   },
      //   {
      //     value: '0',
      //     text: 'Non Faktur',
      //   },
      // ],
      delivery_pickup: 'pickup',
      formPayload: {
        delivery_pickup: 'pickup',
        payment_method: '',
        customer_uuid: '',
        cash_total_payment: 0,
        invoice_status: 0,
        po_so_number: '',
        receiver_name: '',
        receiver_phone_number: '',
        receiver_address: '',
        date: endDate,
        delivery_date: endDate,
      },
      receiver_phone_number: '',
      additional_costs: [],
      customerType: localStorage.getItem('customer_type'),
      customerUuid: localStorage.getItem('customer_uuid'),
      isLoading: false,
      steps: [
        {
          target: '#tour-pickup',
          content: `
                    <div class="text-white size16 mb-1">Ambil Sendiri</div>
                    <div class="text-white2 size14" style="line-height: 24px;">Pelanggan memilih membawa langsung sendiri untuk barang yang dibeli.</div`,
          params: {
            placement: 'top-start', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
          buttonNext: 'Saya mengerti',
          isHideButton: false,
        },
        {
          target: '#tour-delivery',
          content: `
                    <div class="text-white size16 mb-1">Dikirim</div>
                    <div class="text-white2 size14" style="line-height: 24px;">Pelanggan meminta toko mengirimkan barang ke alamat yang ditentukan. Isi alamat pengiriman dengan lengkap, ya!.</div`,
          params: {
            placement: 'top-start', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
          buttonNext: 'Saya mengerti',
          isHideButton: false,
          tutorialFlag: true,
        },
      ],
      userData: JSON.parse(localStorage.getItem('userData')),
      formPayloadDelivery: {
        receiver_name: customerData?.name,
        receiver_phone_number: customerData?.phone_number || '62',
        receiver_address: customerData?.address,
      },
      isFullPayment: false,
      amount_down_payment: 0,
      options: [],
      characterCount: 0,
    }
  },
  watch: {
    amount_down_payment(value, oldValue) {
      const amount = parseFloat(preformatFloat(value))
      this.$emit('amount_dp', value || '')
      if (amount > parseFloat(this.$route.query.total)) {
        this.$swal({
          title: 'Warning!',
          text: 'Jumlah dp tidak boleh lebih dari total belanja',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return false
      }
      // this.$router.push({ name: 'payment.index', query: { bonus: this.$route.query.bonus, total: this.$route.query.total, type_payment: this.isFullPayment ? 1 : 0, amount_dp: value } })
    },
    '$store.state.checkout.isLoading': function (value) {
      this.isLoading = value
    },
    selectedFaktur(value) {
      this.formPayload.invoice_status = value
    },
    additional_costs: {
      handler(value) {
        let cashTotalPayment = parseFloat(this.$route.query.total) || 0
        let total = 0
        this.formPayload.additional_fees = value
        this.$store.commit('cart/SET_FORM_ADDITIONAL_COST', this.additionalCost)
        for (let index = 0; index < value.length; index++) {
          const element = value[index];
          total += parseFloat(preformatFloat(element.additional_fee_amount)) || 0
        }
        cashTotalPayment += total
        this.$store.commit('cart/SET_TOTAL_TRANSAKSI', cashTotalPayment)
        localStorage.setItem('cash_total_payment', cashTotalPayment)
        localStorage.setItem('additional_cost', JSON.stringify(value))
      },
      deep: true,
    },
    formPayload: {
      handler(value) {
        this.invoice_status = value.invoice_status
        this.receiver_phone_number = value.receiver_phone_number
        localStorage.setItem('delivery_pickup', value.delivery_pickup)
        localStorage.setItem('poSO', JSON.stringify(value))
        this.$emit('payload', value)
      },
      deep: true,
    },
    receiver_phone_number(val) {
      const digit1 = Number(String(val).charAt(0));
      this.formPayload.receiver_phone_number = digit1 === 0 ? `62${val.substring(1)}` : val;
    },
    invoice_status: {
      handler(value) {
        localStorage.setItem('invoice_status', value)
        this.formPayload.invoice_status = value
        this.formPayload = {
          ...this.formPayload,
          invoice_status: value,
        }
        localStorage.setItem('payloadDelivery', JSON.stringify(this.formPayload))
      },
      deep: true,
    },
    formPayloadDelivery: {
      handler(value) {
        this.formPayload = {
          ...this.formPayload,
          ...value,
        }
        localStorage.setItem('payloadDelivery', JSON.stringify(value))
      },
      deep: true,
    },
    '$store.state.cashier.userData': {
      handler(value) {
        this.userData = value
      },
      deep: true,
    },
    '$store.state.customer.customer': {
      handler(value) {
        this.formPayload.receiver_name = value.name
        this.formPayload.receiver_phone_number = value.phone_number
        this.formPayload.receiver_address = value.address
      },
      deep: true,
    },
    delivery_pickup(value) {
      localStorage.setItem('delivery_pickup', value)
      this.formPayload.delivery_pickup = value
      this.formPayload.receiver_name = this.$store.state.customer.customer.name
      this.formPayload.receiver_phone_number = this.$store.state.customer.customer.phone_number
      this.formPayload.receiver_address = this.$store.state.customer.customer.address
      if (value === 'pickup') {
        // delete this.formPayload.po_so_number
        delete this.formPayload.delivery_date
        delete this.formPayload.receiver_name
        delete this.formPayload.receiver_phone_number
        delete this.formPayload.receiver_address
        localStorage.removeItem('additional_cost')
        localStorage.removeItem('additional_cost')
        this.additional_costs = []
      }
      this.$emit('deliveryPickupChanged', value);
    },
    'formPayloadDelivery.receiver_name': function(newValue) {
      this.$emit('receiverNameChanged', newValue);
    },
    'formPayloadDelivery.receiver_phone_number': function(newValue) {
      this.$emit('receiverPhoneNumberChanged', newValue);
    },
    'formPayloadDelivery.receiver_address': function(newValue) {
      this.$emit('receiverAddressChanged', newValue);
    },
    // 'formPayload.seller_uuid': function (value) {
    //   this.getSalesDetail(value)
    // },
  },
  created() {
    this.additional_costs = JSON.parse(localStorage.getItem('additional_cost'))
  },
  mounted() {
    const payloadTransaksi = JSON.parse(localStorage.getItem('payloadTransaksi'))

    this.userData = JSON.parse(localStorage.getItem('userData'))
    // this.isFullPayment = JSON.parse(localStorage.getItem('is_full_payment'))
    // this.amount_down_payment = this.$route.query.amount_dp
    this.invoice_status = JSON.parse(localStorage.getItem('invoice_status'))
    
    if (localStorage.getItem('payloadDelivery')) {
      this.formPayloadDelivery = JSON.parse(localStorage.getItem('payloadDelivery'))
    }
    // if (this.$store.state.formWizard.step === 2 && !this.userData.finish_tutorial) {
    //   this.$tours.cashierTour7.start();
    // }

    this.delivery_pickup = localStorage.getItem('delivery_pickup') || 'pickup';
    this.additional_costs = JSON.parse(localStorage.getItem('additional_cost')) || [];

    this.formPayload = {
      delivery_pickup: this.delivery_pickup,
      payment_method: localStorage.getItem('payment_method'),
      customer_uuid: localStorage.getItem('customer_uuid'),
      cash_total_payment: localStorage.getItem('price') || localStorage.getItem('cash_total_payment'),
      date: this.todayDate,
      delivery_date: this.todayDate,
      invoice_status: this.invoice_status,
      po_so_number: this.customerType === 'Non Ritel' ? undefined : this.$route.query.po_so_number,
      seller_uuid: this.$route.query.seller
    };

    if (this.delivery_pickup === 'pickup' && !this.$route.query.deliverypickup) {
      localStorage.removeItem('additional_cost');
      this.additional_costs = [];
    }
    
    this.getSales()

    this.$emit('receiverNameChanged', this.formPayloadDelivery.receiver_name);
    this.$emit('receiverPhoneNumberChanged', this.formPayloadDelivery.receiver_phone_number);
    this.$emit('receiverAddressChanged', this.formPayloadDelivery.receiver_address);
  },
  methods: {
    getAmountDp() {
      const rupiah = document.getElementById('amount-dp')
      rupiah.value = this.formatRupiah(rupiah.value, '')
      this.amount_down_payment = rupiah.value
    },
    methodPaymentSelection() {
      this.isFullPayment = !this.isFullPayment
      localStorage.setItem('is_full_payment', this.isFullPayment)
      if (this.isFullPayment) {
        this.amount_down_payment = ''
      }
    },
    getContentText(val) {
      this.formPayloadDelivery = {
        ...this.formPayloadDelivery,
        receiver_address: val,
      }
    },
    repeateAgain() {
      this.additional_costs.push({
        additional_fee_amount: 0,
        additional_fee_note: '',
      })
    },
    removeItem(index) {
      this.additional_costs.splice(index, 1)
    },
    prosess() {
      this.$router.push({ name: 'payment.success' })
    },
    getRupiah(index) {
      let rupiah = document.getElementById(`nominal-biaya-${index}`)
      rupiah.value = this.formatRupiah(rupiah.value.toString(), '')
      this.additional_costs[index].additional_fee_amount = rupiah.value
    },
    formatRupiah(angka, prefix) {
      const number_string = angka.replace(/[^,\d]/g, '').toString()
      const split = number_string.split(',')
      const sisa = split[0].length % 3
      let rupiah = split[0].substr(0, sisa)
      const ribuan = split[0].substr(sisa).match(/\d{3}/gi)

      if (ribuan) {
        const separator = sisa ? '.' : ''
        rupiah += separator + ribuan.join('.')
      }

      rupiah = split[1] !== undefined ? `${rupiah},${split[1]}` : rupiah
      return prefix === undefined ? rupiah : (rupiah || '')
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.searchSales(loading, search, this)
      } else {
        loading(true)
        setTimeout(() => {
          this.options = []
          this.getSales()
          loading(false)
        }, 500)
      }
    },
    searchSales: _.debounce((loading, search, vm) => {
      vm.$store.dispatch('sales/getData', {
        uuid: '',
        params: {
          search: search,
        },
      })
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          vm.options = []
          response.data.data.data.forEach(item => {
            vm.options.push({
              title: item.name,
              label: item.uuid,
            })
          })
          loading(false)
        })
    }, 300),
    getSalesDetail(id) {
      this.$store.dispatch('sales/getData', {
        uuid: `/${id}`,
        params: '',
      })
        .then(result => {
          // this.uom = result.data.data.name
          this.options.push({
            label: result.data.data.uuid,
            title: result.data.data.name,
          })
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    getSales() {
      this.$store.dispatch('sales/getData', {
        uuid: '',
        params: {
          per_page: 15,
        },
      }).then(result => {
        for (let index = 0; index < result.data.data.data.length; index++) {
          const element = result.data.data.data[index]
          this.options.push({
            title: element.name,
            label: element.uuid,
          })
        }
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    handlePhoneNumberInput(value) {
      if (!value) {
        this.formPayloadDelivery.receiver_phone_number = '62';
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/tour.scss';

.v-tour {
  .v-step {
    background-color: #334155;

    .v-step__arrow  {
      border-color: #334155;
    }
  }
}
.card--sender {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  width: 100%;
  padding: 3rem 2rem;
  margin-bottom: 2rem;
  cursor: pointer;

  background: #FFFFFF;

  &.active {
    background: rgba(82, 206, 194, 0.1);
    border: 1px solid #45B6AB;

    h6 {
      color: #45B6AB;
    }
  }

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    background: #FEF9F5;
    border-radius: 50%;
    box-shadow: inset 1.67273px 1.67273px 3.34545px rgba(230, 219, 211, 0.2);

    .shopping-cart {
      font-weight: bolder;
      color: #F1B277;
    }

    .truck {
      font-weight: bolder;
      color: #E04444;
    }
  }

  h6 {
    margin-top: 5px;
    font-weight: 800;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
  }
}
</style>

<style lang="scss" scoped>
  body {
    background: #FFFFFF;
  }

  .form-payment {
    height: 100vh;

    .custom__form--input mb-0 {
      label {
        margin-bottom: 1rem !important;
      }
    }

    .image-box {
      width: 46px;
      height: 46px;
    }

    @mixin title-card {
      margin-bottom: 0 !important;
      font-weight: 800;
      font-size: 14px;
      color: #2B324F;
    }

    .card {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
      border-radius: 12px;

      &.active--payment {
        background: rgba(82, 206, 194, 0.1);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        border: 1px solid #45B6AB;

        h5 {
          color: #45B6AB !important;
          font-weight: 800;
          margin-bottom: 0px;
        }
      }

      &.card--full__payment {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding: 21.5px 16px;

        h5 {
          @include title-card;
          align-self: center;
        }

        span {
          font-weight: 800;
          font-size: 16px;
          color: #2B324F;
        }
      }

      &.card--down__payment {
        display: flex;
        justify-content: space-between;
        padding: 21.5px 16px;

        h5 {
          @include title-card;
        }

        small {
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
  }
</style>
